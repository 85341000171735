
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { ScrollComponent } from '@/assets/ts/components/_ScrollComponent';
import { MenuComponent } from '@/assets/ts/components/MenuComponent';
import { version } from '@/core/helpers/documentation';
import { asideMenuIcons } from '@/core/helpers/config';
import MainMenuConfig from './Menu';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  name: 'kt-menu',
  data() {
    return {
      MainMenuConfig: [
        {
          heading: 'menu',
          route: '/report',
          pages: [
            {
              sectionTitle: 'Course Reports',
              route: '/report/batch-monitor',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
              fontIcon: 'bi-app-indicator',
              sub: [
                {
                  heading: 'Ongoing Course Summary',
                  route: '/report/on-going-course-summary',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Completed Course Summary',
                  route: '/report/completed-course-summary',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Course Summary (Monthly Breakdown)',
                  route: '/report/course-wise-break-down',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Course Wise Training Statistics Report',
                  route: '/report/course-trainningStatistics',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Course Category Report',
                  route: '/report/course-category-report',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Mid Level Course Report',
                  route: '/report/mid-level-course',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Unique Course Details',
                  route: '/report/unique-course',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Course Activity Report',
                  route: '/report/course-activity',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },

                 {
                  heading: 'ToT Course Report',
                  route: '/report/tot-course',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
              ],
            },
            {
              sectionTitle: 'Batch Reports',
              route: '/report/batch-monitor',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
              fontIcon: 'bi-app-indicator',
              sub: [
                {
                  heading: 'Batch Report',
                  route: '/report/batch-monitor',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Pending Batch Activity',
                  route: '/report/pending-activities',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
              ],
            },
            {
              sectionTitle: 'Trainee Reports',
              route: '/report/batch-monitor',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
              fontIcon: 'bi-app-indicator',
              sub: [
                {
                  heading: 'Student Report(Course & Gender)',
                  route: '/report/course-student',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Student Age Group List',
                  route: '/report/student-age-group-list',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Ethnic Minority & Disable Report',
                  route: '/report/ethnicdisable',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Ethnic Minority Report',
                  route: '/report/ethnic-minority',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Physically Disable Report',
                  route: '/report/physicallydisabled',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Poor Trainee Summary',
                  route: '/report/poor-trainee-summary',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Ethnic Group and Physically Challenged Trainee',
                  route: '/report/ethnic-challenged-trainee',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Gender Equity Report',
                  route: '/report/gender-equity',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
              ],
            },
            {
              sectionTitle: 'Enrollment Reports',
              route: '/report/batch-monitor',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
              fontIcon: 'bi-app-indicator',
              sub: [
                {
                  heading: 'Enrollment Summary Report',
                  route: '/report/course-summary',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Institute Wise Enrollment Summary',
                  route: '/report/institute-enrollment-summary',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                // {
                //   heading: 'Coure Wise Enrollment Summary',
                //   route: '/report/institute-enrollment-summary-report',
                //   fontIcon: 'fa-user-secret',
                //   svgIcon: 'media/icons/duotune/general/gen019.svg',
                // },
                {
                  heading: 'Batch Wise Enrollment Summary',
                  route: '/report/batch-enrollment-summary',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
              ],
            },
            {
              sectionTitle: 'Attendance Reports',
              route: '/report/batch-monitor',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
              fontIcon: 'bi-app-indicator',
              sub: [
                {
                  heading: 'Student Attendance List',
                  route: '/report/student-attendance-list',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Monitor Trainee Attendance',
                  route: '/report/monitor-trainee-attendance',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Date Wise Trainee Attendance',
                  route: '/report/datewise-trainee-attendance',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
              ],
            },
            {
              sectionTitle: 'Job Placement Reports',
              route: '/report/batch-monitor',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
              fontIcon: 'bi-app-indicator',
              sub: [
                {
                  heading: 'Batch Wise Job Placement Summary',
                  route: '/report/batch-wise-placement-summary',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Trainee Job Placement Summary',
                  route: '/report/trainee-job-placement-summary',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Overseas Job Placement Summary',
                  route: '/report/overseas-job-placement-summary',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Job Placement List By Bill',
                  route: '/report/claim-3-job-placement-summary',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
              ],
            },
            {
              sectionTitle: 'Monitoring Reports',
              route: '/report/batch-monitor',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
              fontIcon: 'bi-app-indicator',
              sub: [
                {
                  heading: 'Motor Driving License Report',
                  route: '/report/batch-motor-driving',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Trainee Allowance Summary',
                  route: '/report/trainee-allownce-summary',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Trainee Allowance Summary (Running Batch)',
                  route: '/report/trainee-allowance-summary',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Motor Driving Course Summary',
                  route: '/report/motor-driving-course-summary',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Implementation Progress Report',
                  route: '/report/implementation-progress',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Course Wise Training Activities Report',
                  route: '/report/course-wise-training-activity',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
              ],
            },
            {
              sectionTitle: 'TMS Reports',
              route: '/report/nid-report',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
              fontIcon: 'bi-app-indicator',
              sub: [
                {
                  heading: 'NID Report',
                  route: '/report/nid-report',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'BCN Report',
                  route: '/report/bcn-report',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Cumulative Progress Report',
                  route: '/report/cumulative-progress-report',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Activity Status History',
                  route: '/report/active-status-history',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'District Wise Training Report',
                  route: '/report/district-trainee-summary',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Status Monitoring Report',
                  route: '/report/status-monitoring-report',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Institute Wise Training Statistics Report',
                  route: '/report/institute-wise-training-statistic',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
              ],
            },
            {
              sectionTitle: 'External Monitoring Report',
              route: '/report/running-batch',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
              fontIcon: 'bi-app-indicator',
              sub: [
                {
                  heading: 'Running Course & Batch',
                  route: '/report/running-batch',
                  fontIcon: 'fa-list',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                 {
                  heading: 'All Course & Batch',
                  route: '/report/all-batch',
                  fontIcon: 'fa-list',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Enrollment Summary',
                  route: '/report/institute-enrollment-summary-report',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
              ],
            },
             {
              sectionTitle: 'Asset Register',
              route: '/report/asset-register',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
              fontIcon: 'bi-app-indicator',
              sub: [
                {
                  heading: 'Add Asset Register',
                  route: '/report/new-asset-register',
                  fontIcon: 'fa-list',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Pending Asset List',
                  route: '/report/pending-asset-register',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
                {
                  heading: 'Asset Register Report',
                  route: '/report/asset-register',
                  fontIcon: 'fa-user-secret',
                  svgIcon: 'media/icons/duotune/general/gen019.svg',
                },
              ],
            },
            // {
            //   heading: 'Student Report',
            //   route: '/report/student-report',
            //   fontIcon: 'fa-user-secret',
            //   svgIcon: 'media/icons/duotune/general/gen019.svg',
            // },

            // {
            //   heading: "Gender Wise Student",
            //   route: "/enrollment/training-license",
            //   fontIcon: "fa-user-secret",
            //   svgIcon: "media/icons/duotune/general/gen019.svg",
            // },

            // {
            //   heading: "Completed Course summary",
            //   route: "/enrollment/training-license",
            //   fontIcon: "fa-user-secret",
            //   svgIcon: "media/icons/duotune/general/gen019.svg",
            // },

            // {
            //   heading: 'Active Status History',
            //   route: '/report/active-status-history',
            //   fontIcon: 'fa-user-secret',
            //   svgIcon: 'media/icons/duotune/general/gen019.svg',
            // },

            // {
            //   heading: "Course Summary(Private)",
            //   route: "/enrollment/training-license",
            //   fontIcon: "fa-user-secret",
            //   svgIcon: "media/icons/duotune/general/gen019.svg",
            // },
            // {
            //   heading: "Course Summary(BB-SME & PKSF)",
            //   route: "/enrollment/training-license",
            //   fontIcon: "fa-user-secret",
            //   svgIcon: "media/icons/duotune/general/gen019.svg",
            // },
            // {
            //   heading: "Course Summary(Monthly BreakDown)",
            //   route: "/enrollment/training-license",
            //   fontIcon: "fa-user-secret",
            //   svgIcon: "media/icons/duotune/general/gen019.svg",
            // },
          ],
        },
      ] as any,
    };
  },
  components: {},
  async created() {
    await this.menucheck();
  },
  methods: {
    async menucheck() {
      let entity_type_id = VueCookieNext.getCookie('_seip_entity_type');
      if (entity_type_id == 1011) {
        this.MainMenuConfig = [
          {
            heading: 'menu',
            route: '/report',
            pages: [
              {
                sectionTitle: 'Monitoring Reports',
                route: '/report/batch-monitor',
                svgIcon: 'media/icons/duotune/general/gen025.svg',
                fontIcon: 'bi-app-indicator',
                sub: [
                  {
                    heading: 'Batch Report',
                    route: '/report/batch-monitor',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Student Report(Course & Gender)',
                    route: '/report/course-student',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Student Attendance List',
                    route: '/report/student-attendance-list',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Student Age Group List',
                    route: '/report/student-age-group-list',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Trainee Allowance Summary',
                    route: '/report/trainee-allownce-summary',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Trainee Allowance Summary (Running Batch)',
                    route: '/report/trainee-allowance-summary',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                ],
                
              },
               {
                sectionTitle: 'Asset Register',
                route: '/report/asset-register',
                svgIcon: 'media/icons/duotune/general/gen025.svg',
                fontIcon: 'bi-app-indicator',
                sub: [
                  {
                    heading: 'Add Asset Register',
                    route: '/report/new-asset-register',
                    fontIcon: 'fa-list',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Pending Asset List',
                    route: '/report/pending-asset-register',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Asset Register Report',
                    route: '/report/asset-register',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                ],
              },
              {
                sectionTitle: 'External Monitoring Report',
                route: '/report/running-batch',
                svgIcon: 'media/icons/duotune/general/gen025.svg',
                fontIcon: 'bi-app-indicator',
                sub: [
                  {
                    heading: 'Running Course & Batch',
                    route: '/report/running-batch',
                    fontIcon: 'fa-list',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'All Course & Batch',
                    route: '/report/all-batch',
                    fontIcon: 'fa-list',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Enrollment Summary',
                    route: '/report/institute-enrollment-summary-report',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                ],
              },
            ],
          },
        ];
      }
      if (entity_type_id == 1010) {
        this.MainMenuConfig = [
          {
            heading: 'menu',
            route: '/report',
            pages: [
              {
                sectionTitle: 'TMS Reports',
                route: '/report/nid-report',
                svgIcon: 'media/icons/duotune/general/gen025.svg',
                fontIcon: 'bi-app-indicator',
                sub: [
                  {
                    heading: 'NID Report',
                    route: '/report/nid-report',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'BCN Report',
                    route: '/report/bcn-report',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Cumulative Progress Report',
                    route: '/report/cumulative-progress-report',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'District Wise Training Report',
                    route: '/report/district-trainee-summary',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Status Monitoring Report',
                    route: '/report/status-monitoring-report',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Monitor Trainee Attendance',
                    route: '/report/monitor-trainee-attendance',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                   {
                    heading: 'Date Wise Trainee Attendance',
                    route: '/report/datewise-trainee-attendance',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Trainee Job Placement Summary',
                    route: '/report/trainee-job-placement-summary',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Institute Wise Training Statistics Report',
                    route: '/report/institute-wise-training-statistic',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Course Wise Training Statistics Report',
                    route: '/report/course-trainningStatistics',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Pending Batch Activities',
                    route: '/report/pending-activities',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Trainee Allowance Summary',
                    route: '/report/trainee-allownce-summary',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Trainee Allowance Summary (Running Batch)',
                    route: '/report/trainee-allowance-summary',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                ],
              },
               {
                sectionTitle: 'Asset Register',
                route: '/report/asset-register',
                svgIcon: 'media/icons/duotune/general/gen025.svg',
                fontIcon: 'bi-app-indicator',
                sub: [
                  {
                    heading: 'Add Asset Register',
                    route: '/report/new-asset-register',
                    fontIcon: 'fa-list',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Pending Asset List',
                    route: '/report/pending-asset-register',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                  {
                    heading: 'Asset Register Report',
                    route: '/report/asset-register',
                    fontIcon: 'fa-user-secret',
                    svgIcon: 'media/icons/duotune/general/gen019.svg',
                  },
                ],
              },
            ],
          },
        ];
      }
      // for only seip
      if (entity_type_id == 1004) {
        this.MainMenuConfig?.[0]?.pages.push({
          svgIcon: 'media/icons/duotune/general/gen025.svg',
          fontIcon: 'bi-app-indicator',
          heading: 'Disburse Statement',
          route: '/report/disburse-statement',
        });
      }
    },
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
